<template>
    <detalhe-titulo :titulo="titulo"></detalhe-titulo>
</template>

<script>
import DetalheTitulo from '@/components/DetalheTitulo';
export default {
    components: {
        DetalheTitulo
    },
    data() {
        return {
            titulo: {}
        };
    },
    mounted() {}
};
</script>
